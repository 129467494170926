import {defineStore} from 'pinia'
import http from "@/http";

export const useAuthenticationStore = defineStore(
    "authentication",
    {
        state: () => ({
            token: localStorage.getItem('token') ?? null,
        }),
        getters: {
            getToken(state) {
                return state.token;
            },
            isLogged(state) {
                return state.token !== null;
            }
        },
        actions: {
            async tryLogin(username, password) {
                try {
                    const data = await http.post('/Account/authenticate', {
                        username: username,
                        password: password
                    })

                    this.token = data.data.tokenData.token;

                    localStorage.setItem('token', this.token);

                    localStorage.setItem('user', JSON.stringify({
                        username: data.data.username,
                        firstName: data.data.firstName,
                        lastName: data.data.lastName,
                    }));

                } catch (error) {

                }
            },
            async tryResetPassword(email) {
                try {
                    await http.post('/Account/forgot-password', {
                        email: email,
                    })
                } catch (error) {

                }
            },
            async logout() {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.token = null;
            }
        },
    })
