<template>
  <AuthCard
      title="Password Recovery"
      subtitle="No worries! Enter your email address and we’ll send you reset instructions."
  >
    <template #form>
      <form action="post" @submit.prevent="resetPassword">
        <div class="flex flex-col mt-4 ">
          <label class="text-black font-medium text-sm" for="email">Email</label>
          <input v-model="resetPasswordForm.email" type="text" name="email" required
                 class="focus:ring-primary focus:border-primary bg-white border border-gray-300 p-2 py-1 mt-1">
        </div>
        <button type="submit" class="block bg-primary rounded w-44 mx-auto mt-4 text-white p-1.5">Reset Password</button>
      </form>
    </template>

    <template #footer>
      <div class="mt-2 flex justify-center gap-2 text-sm text-center">
        <router-link :to="{name: 'auth.login'}" class="text-primary font-medium">← Return to login</router-link>
      </div>
    </template>

  </AuthCard>
</template>

<script>
export default {
  name: 'PasswordResetPage',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import {useRouter} from "vue-router";
import {useAuthenticationStore} from "@/store/authentication";
import {ref} from "vue";
import AuthCard from "@/components/AuthCard";

const store = useAuthenticationStore();
const router = useRouter();

const resetPasswordForm = ref({
  email: '',
});

const resetPassword = () => {
  const {email} = resetPasswordForm.value;

  store.tryResetPassword(email)
      .then(() => {
        router.push({name: 'auth.login'});
      })
}
</script>
