import {defineStore} from 'pinia'
import http from '@/http'

export const useCatalogStore = defineStore(
    "catalog",
    {
        state: () => ({
            catalog: '',
        }),
        getters: {
            getCatalogItems(state) {
                return state.catalog;
            },
        },
        actions: {
            async fetchCatalog() {
                try {
                    const data = await http.get('/Catalog/GetCatalog', {
                        params: {
                            PageNumber: 1,
                            PageSize: 12,
                        }
                    })
                    this.catalog = data.data
                } catch (error) {
                    console.log(error)
                }
            }
        },
    })
