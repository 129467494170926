<template>
  <div class="flex">
    <aside class="sidebar bg-white w-fit min-h-screen shadow relative">
      <div class="brand px-4 pt-16">
        <img src="@/assets/logo.png" class="w-64"/>
      </div>
      <div class="mt-14 pl-7">
        <div class="space-y-5">
          <a href="#" class="flex gap-4 items-center hover:text-primary transition-all py-1">
            <img src="@/assets/icon-home.svg" alt="Dashboard"/> <span>Dashboard</span>
          </a>
          <a href="#" class="flex gap-4 items-center hover:text-primary transition-all py-1">
            <img src="@/assets/icon-bar.svg" alt="Dashboard"/> <span>Reports</span>
          </a>
          <a href="#" :class="'text-primary border-r-4 border-primary'"
             class="flex gap-4 items-center hover:text-primary transition-all py-1">
            <img src="@/assets/icon-home.svg" alt="Catalog"/> <span>Catalog</span>
          </a>
          <a href="#" class="flex gap-4 items-center hover:text-primary transition-all py-1">
            <img src="@/assets/icon-money.svg" alt="Purchasing"/> <span>Purchasing</span>
          </a>
          <a href="#" class="flex gap-4 items-center hover:text-primary transition-all py-1">
            <img src="@/assets/icon-package.svg" alt="Orders"/> <span>Orders</span>
          </a>
          <a href="#" class="flex gap-4 items-center hover:text-primary transition-all py-1">
            <img src="@/assets/icon-settings.svg" alt="Settings"/> <span>Settings</span>
          </a>
        </div>
      </div>
      <div class="mt-14 pl-7 mb-8 absolute inset-x-0 bottom-0">
        <div class="space-y-5">
          <a href="#" class="flex gap-4 items-center hover:text-primary transition-all">
            <img src="@/assets/icon-help.svg" alt="Help"/> <span>Help</span>
          </a>
          <a href="#" @click.prevent="logout" class="flex gap-4 items-center hover:text-primary transition-all">
            <img src="@/assets/icon-user.svg" alt="Help"/> <span>Logout</span>
          </a>
        </div>
      </div>
    </aside>

    <main class="content w-full">

      <section class="shadow bg-white h-14 px-4 py-2 flex items-center">
        <div class="search relative w-full">
          <span class="absolute top-3 left-4"><img src="@/assets/icon-search.svg" alt="Search" class="h-4 w-4"/></span>
          <input
              type="search"
              class="focus:ring-primary focus:border-primary bg-white border border-gray-300 p-2 py-2 pl-10 rounded-full w-full text-sm"
              placeholder="Search for SKU, Order, or PO"
          >

        </div>
        <div class="user w-64 flex gap-2 justify-center items-center">
          <div class="avatar border border-gray-400 rounded-full h-8 w-8 flex justify-center items-center">
            <img src="@/assets/icon-user.svg" alt="Avatar" class="w-3 h-3"/>
          </div>
          <div class="user-info">
            <p class="text-primary text-xs">{{ user.username }}</p>
            <p class="text-primary text-xs">Company Name</p>
          </div>
        </div>
        <div class="w-16 notifications">
          <a href="#" class="flex items-center justify-center">
            <img src="@/assets/icon-bell.svg" alt="Notifications" class="w-5 h-5"/>
          </a>
        </div>
      </section>

      <div class="w-full px-4 mt-8">
        <h1>{{ props.title }}</h1>
      </div>
      <div class="px-8 my-8 mx-auto">
        <slot/>
      </div>

    </main>

  </div>
</template>

<script>
export default {
  name: "CoreTemplate"
}
</script>

<script setup>
import {useRouter} from "vue-router";
import {useUserStore} from "@/store/user";
import {useAuthenticationStore} from "@/store/authentication";

const props = defineProps({
  title: {
    type: String,
    default: ""
  }
});

const router = useRouter();
const authStore = useAuthenticationStore();
const userStore = useUserStore();
const user = userStore.getUser;

const logout = () => {
  authStore.logout()
      .then(() => {
        router.push({name: 'auth.login'});
      });
}
</script>

<style scoped>

</style>
