<template>
  <div class="sm:py-32 py-10">
    <div class="mx-auto flex justify-center">
      <img src="@/assets/logo.png"/>
    </div>
    <div class="bg-white mx-auto rounded w-full sm:min-w-96 sm:w-fit sm:p-8 sm:px-24 mt-4 p-4 shadow">
      <p class="text-primary text-2xl text-center font-light">{{ props.header }}</p>
      <p class="mt-2 text-gray-600 font-light text-sm text-center sm:w-72">{{ props.subtitle }}</p>
      <div>
        <slot name="form"></slot>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "AuthBox",
}
</script>

<script setup>
const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  }
})
</script>
