import * as VueRouter from 'vue-router';

import MainPage from "@/pages/Catalog/IndexPage";
import LoginPage from "@/pages/Auth/LoginPage";
import ResetPasswordPage from "@/pages/Auth/ResetPasswordPage";
import {useAuthenticationStore} from "@/store/authentication";

const notAuthenticatedGuard = (to) => {
    const authStore = useAuthenticationStore();
    if (
        !authStore.isLogged &&
        // ❗️ Avoid an infinite redirect
        to.name !== 'auth.login'
    ) {
        return {name: 'auth.login'}
    }
}

const authenticatedGuard = (to) => {
    const authStore = useAuthenticationStore();
    if (
        authStore.isLogged &&
        // ❗️ Avoid an infinite redirect
        to.name !== 'catalog'
    ) {
        // redirect the user to the login page
        return {name: 'catalog'}
    }
}

const routes = [
    {
        path: '/',
        component: MainPage,
        name: 'catalog',
        beforeEnter: notAuthenticatedGuard
    },
    {
        path: '/login',
        component: LoginPage,
        name: 'auth.login',
        beforeEnter: authenticatedGuard
    },
    {
        path: '/reset-password',
        component: ResetPasswordPage,
        name: 'auth.reset_password',
        beforeEnter: authenticatedGuard
    },
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes,
})

export default router
