import {createApp} from 'vue'
import App from '@/App.vue'
import '@/styles/main.css'
import router from '@/router/index'
import {createPinia} from 'pinia'

createApp(App)
    .use(createPinia())
    .use(router)
    .mount('#app')
