import axios from 'axios'
import {useAuthenticationStore} from "@/store/authentication";
import router from "@/router";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(
    (config) => {
        if (config.headers) {
            config.headers["Authorization"] = `Bearer ${useAuthenticationStore().getToken}`
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

http.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        useAuthenticationStore().logout();
        router.push({name: 'auth.login'});
    } else {
        return Promise.reject(error)
    }
})

export default http;
