<template>
  <CoreTemplate title="View Catalog">
    <table class="w-full border-separate" style="border-spacing: 0">
      <thead class="bg-skyblue h-10">
      <tr class="bg-gray-50 bg-opacity-75 font-medium text-xs text-gray-900 text-left text-sm">
        <th scope="col" class="lg:table-cell">
          <input type="checkbox" class="border-primary border-2 h-4 w-4 ml-6 rounded text-primary focus:ring-transparent sm:left-4"/>
        </th>
        <th scope="col" class="lg:table-cell">
          SKU
        </th>
        <th scope="col" class="lg:table-cell">
          Product Name
        </th>
        <th scope="col" class="lg:table-cell">
          Rating
        </th>
        <th scope="col" class="lg:table-cell">
          Brand
        </th>
        <th scope="col" class="lg:table-cell">
          Buyer
        </th>
        <th scope="col" class="lg:table-cell">
          Qty in Stock
        </th>
        <th scope="col" class="lg:table-cell">
          Qty on Order
        </th>
        <th scope="col" class="lg:table-cell">
          Landed Cost
        </th>
        <th scope="col" class="lg:table-cell">
          Floor Price
        </th>
        <th scope="col" class="lg:table-cell">
          Wholesale Price
        </th>
         <th scope="col" class="lg:table-cell">
          Retail Price
        </th>
        <th scope="col" class="lg:table-cell">
          Item Dimensions
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in catalogItems.data" :key="index" class="divide-y divide-gray-200 bg-white hover:bg-gray-50">
        <td :class="index === 0 ? 'border-t border-gray-200' : ''">
          <input type="checkbox" class="ml-6 h-4 w-4 rounded border-primary border-2 text-primary focus:ring-transparent sm:left-6"/>
        </td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs text-primary truncate flex justify-start gap-2 items-center">
          <div class="bg-cover bg-center w-12 h-12 rounded shadow" :style="{'background-image': item.itemImageUrl ?'url(' + item.itemImageUrl + ')' :'url(https://via.placeholder.com/150)'}" /> <span>{{ item.sku }}</span>
        </td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs"><p class="w-48">{{ item.productName }}</p></td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs"><span class="bg-gray-500 text-white font-medium rounded-full h-7 w-7 block flex items-center justify-center">{{
            item.itemRating
          }}</span></td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.brandName }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.buyerId }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.qtyInStock }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.onOrderQtyUnshipped }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.defaultFloor }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.whFloor }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.whPrice }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.retailPrice }}</td>
        <td :class="index === 0 ? 'border-t border-gray-200' : ''" class="py-3 text-xs">{{ item.shippingWeight }} x {{ item.shippingWidth }}</td>
      </tr>
      </tbody>
    </table>
  </CoreTemplate>
</template>

<script>
export default {
  name: 'MainPage',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import {useRouter} from "vue-router";
import {computed, onMounted} from "vue";
import {useAuthenticationStore} from "@/store/authentication";
import {useCatalogStore} from "@/store/catalog";
import CoreTemplate from "@/components/CoreTemplate";

const router = useRouter();
const authStore = useAuthenticationStore();
const catalogStore = useCatalogStore();
const catalogItems = computed(() => catalogStore.getCatalogItems);

const logout = () => {
  authStore.logout()
      .then(() => {
        router.push({name: 'auth.login'});
      });
}

onMounted(() => {
  catalogStore.fetchCatalog();
});
</script>
