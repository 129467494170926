<template>
  <AuthCard
      title="Welcome Back"
      subtitle="Enter your credentials to sign in to your account"
  >
    <template #form>
      <form action="post" @submit.prevent="login">
        <div class="flex flex-col mt-4 ">
          <label class="text-black font-medium text-sm" for="email">Username</label>
          <input v-model="loginForm.username" type="text" required name="username"
                 class="focus:ring-primary focus:border-primary bg-white border border-gray-300 p-2 py-1 mt-1">
        </div>
        <div class="flex flex-col mt-4 ">
          <label class="text-black font-medium text-sm" for="password">Password</label>
          <input v-model="loginForm.password" type="password" required name="password"
                 class="focus:ring-primary focus:border-primary bg-white border border-gray-300 p-2 py-1 mt-1">
        </div>
        <div class="flex justify-start gap-1 items-center mt-2">
          <input name="remember_me" type="checkbox" class="focus:ring-transparent h-4 w-4 text-primary font-medium border border-gray-400 rounded"/> <span
            class="font-light text-gray-700 text-sm">Remember me</span>
        </div>
        <button type="submit" class="block bg-primary rounded w-44 mx-auto mt-4 text-white p-1.5">Login</button>
      </form>
    </template>

    <template #footer>
      <div class="mt-2 flex justify-center gap-2 text-sm text-center">
        <span class="text-gray-400 font-light">Forgot your password?</span>
        <router-link :to="{name: 'auth.reset_password'}" class="text-primary font-medium">Reset Password</router-link>
      </div>
    </template>

  </AuthCard>
</template>

<script>
export default {
  name: 'LoginPage',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import {ref} from "vue";
import {useAuthenticationStore} from '@/store/authentication';
import {useRouter} from "vue-router";
import AuthCard from "@/components/AuthCard";

const store = useAuthenticationStore();
const router = useRouter();

const loginForm = ref({
  username: '',
  password: ''
});

const login = () => {
  const {username, password} = loginForm.value;

  store.tryLogin(username, password)
      .then(() => {
        router.push({name: 'catalog'});
      })
}

</script>

<style scoped>

</style>
