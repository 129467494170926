import {defineStore} from 'pinia'

export const useUserStore = defineStore(
    "users",
    {
        state: () => ({
            user: localStorage.getItem('user') ?? null,
        }),
        getters: {
            getUser(state) {
                return JSON.parse(state.user);
            },
        },
        actions: {

        },
    })
